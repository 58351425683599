import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./WeeklyCalendar.css";
import { Modal } from "antd";
import CalendarPopup from "./CalendarPopup";
import { CustomToolbar } from "./CustomToolbar";

const localizer = momentLocalizer(moment);

export const WeeklyCalendar = ({
  selectedMeetingRoom,
  selectedPlace,
  events,
  selectSlot,
  startDate,
  endDate,
  startTime,
  endTime,
  selectedSlot,
  dateRange,
  setDateRange,
}) => {
  console.log("26",events)
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendarPopupData, setCalendarPopupData] = useState(null);
  const [displayEvents, setDisplayEvents] = useState(null);

  const showModal = (key) => {
    setCalendarPopupData(key);
    setIsModalOpen(true);
  };
  const handlePopupCancel = () => {
    setCalendarPopupData(null);
    setIsModalOpen(false);
  };

  const updateFilteredEvents = useCallback(() => {
    if (selectedMeetingRoom) {
      const today = dayjs().startOf('day');
      const filtered = events.filter((event)=> dayjs(event.start) > today)
        .map((event) => ({ ...event, isFilteredEvent: true }));
      setFilteredEvents(filtered);
    } else {
      setFilteredEvents([]);
    }
  }, [selectedMeetingRoom, events, dateRange]);

  useEffect(() => {
    updateFilteredEvents();
  }, [selectedMeetingRoom, events, dateRange, updateFilteredEvents]);

  const eventPropGetter = useCallback(
    (meet, start) => ({
      ...(start.toISOString().split("T")[0] < dayjs().format("YYYY-MM-DD") && {
        style: {
          backgroundColor: "#C7C8CC",
          color: "#000000",
        },
      }),
      ...(meet.createdBy === meet.empCode &&
        start.toISOString().split("T")[0] < dayjs().format("YYYY-MM-DD") && {
          style: {
            backgroundColor: "#C7C8CC",
            color: "#000000",
          },
        }),
      ...(meet.createdBy === meet.empCode &&
        start.toISOString().split("T")[0] >= dayjs().format("YYYY-MM-DD") && {
          style: {
            backgroundColor: "#90EE90",
            color: "#000000",
          },
        }),
      ...(meet.createdBy !== meet.empCode &&
        start.toISOString().split("T")[0] >= dayjs().format("YYYY-MM-DD") && {
          style: {
            backgroundColor: "#ADD8E6",
            color: "#000000",
          },
        }),
    }),
    []
  );

  // const handleSelectEvent = (event) => {
  //   if (event.isFilteredEvent) {
  //     showModal(event);
  //   }
  // };

  useEffect(() => {
    setDisplayEvents(selectedSlot);
  }, [selectedSlot]);

  const addEvent = (start, end) => {
    const newEvent = {
      start: start.toDate(),
      end: end.toDate(),
      title: "Selected Slot",
      isFilteredEvent: false,
    };

    setDisplayEvents(newEvent);
  };

  useEffect(() => {
    const startDateTime = dayjs(
      `${startDate.format("YYYY-MM-DD")}T${startTime.format("HH:mm")}`
    );
    const endDateTime = dayjs(
      `${startDate.format("YYYY-MM-DD")}T${endTime.format("HH:mm")}`
    );

    if (startDateTime.isValid() && endDateTime.isValid()) {
      addEvent(startDateTime, endDateTime);
    }
  }, [startDate, endDate, startTime, endTime]);

  const handleNavigate = (date) => {
    setDateRange({
      start: moment(date).startOf("week").toDate(),
      end: moment(date).endOf("week").toDate(),
    });
  };

  return (
    <div className="week-view-container" style={{ height: "85vh", width: "100%", marginLeft: "1rem" }}>
      <Calendar
        localizer={localizer}
        events={[...filteredEvents, displayEvents]}
        defaultView="week"
        views={["week"]}
        style={{ flex: 1, border: "1px solid #ddd", borderRadius: "5px" }}
        eventPropGetter={eventPropGetter}
        selectable
        onSelectSlot={(selectedSlot) => selectSlot(selectedSlot)}
        onNavigate={handleNavigate}
        min={moment().startOf("day").toDate()}
        max={moment().endOf("day").toDate()}
        scrollToTime={moment().startOf("day").hour(9).toDate()}
        popup
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar: CustomToolbar,
        }}
      />
      <Modal
        open={isModalOpen && calendarPopupData != null}
        onCancel={handlePopupCancel}
        footer={null}
        style={{ width: "100px", position: "fixed", top: "0px", left: "35%" }}
      >
        <CalendarPopup
          event={calendarPopupData}
          handlePopupCancel={handlePopupCancel}
        />
      </Modal>
    </div>
  );
};
