import React, { useState } from "react";
import { Button, Flex, Modal } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { meetingRoomApis } from "../../api";
import toast from "react-hot-toast";
const DeleteMeetingRoomPopup = ({
  hide,
  clientId,
  meetingRoomData,
  popupData,
}) => {
  
  let paramsData = {};
  if ("isActivating" in popupData) {
    paramsData.activateRoom = popupData.isActivating;
  } else {
    paramsData.underMaintenance = popupData.isUnderMaintenance;
  }

  const handleOk = async () => {
    const toastId = toast.loading("Loading...");
    try {
      await meetingRoomApis.deleteMeetingRoom(clientId, paramsData);
      hide();
      if ("isActivating" in popupData) {
        toast.success(
          popupData.isActivating
            ? "Meeting room activated"
            : "Meeting room deactivated"
        );
      } else {
        toast.success(
          popupData.isUnderMaintenance
            ? "Meeting room shifted to maintenance"
            : "Meeting room now available"
        );
      }
    } catch (err) {
      console.log("error in deleting meeting room api ", err);
      if ("isActivating" in popupData) {
        toast.error(
          `Meeting room could not be ${
            popupData.isActivating ? "activated" : "deactiavted"
          }`
        );
      } else {
        toast.error(
          `Meeting room could not be shifted to ${
            popupData.isUnderMaintenance ? "maintenance" : "available"
          } `
        );
      }
      
    }
    toast.dismiss(toastId);
  };
  return (
    <>
      <Flex vertical gap="" style={{ width: "100%" }}>
        <div className="delete-btn-f1">
          <WarningTwoTone twoToneColor={"#bb2124"} />
          {`  `}Are you sure you want to{" "}
          {"isActivating" in popupData
            ? popupData.isActivating
              ? `activate ${meetingRoomData.name}`
              : `deactivate ${meetingRoomData.name}`
            : popupData.isUnderMaintenance
            ? `shift ${meetingRoomData.name} to maintenance`
            : `shift ${meetingRoomData.name} to available`}{" "}
          ?
        </div>
        <div className="deletePopup">
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>
          <Button type="" onClick={() => hide()}>
            Cancel
          </Button>
        </div>
      </Flex>
    </>
  );
};
export default DeleteMeetingRoomPopup;
