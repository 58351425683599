import React from 'react';
import { Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { userNameEmpId } from '../../userRole';


const items = [
  {
    key: '1',
    label: (
      <a>
        {userNameEmpId.name}
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a>
        {userNameEmpId.empId}
      </a>
    ),
  },
];

const User = () => {
  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottom"
    >
        <UserOutlined style={{ color: '#fff', cursor: 'pointer', fontSize:"20px" }} />
    </Dropdown>
  );
};

export default User;
