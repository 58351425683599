import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Flex,
  Select,
  Form,
  Switch,
  TimePicker,
  Modal,
  Input,
} from "antd";
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import "./styles.css";
import {
  inviteesApi,
  meetingRoomApis,
  roomBookingApi,
  staticApis,
} from "../../api";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import DeletePopup from "./DeleteMeetingPopup";
import MeetingInformationPopup from "./MeetingInformationPopup";
import {employeeId} from '../../userRole';

const CalendarPopup = ({ event, handlePopupCancel }) => {
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [invitees, setInvitees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [form] = Form.useForm();

  const isPastMeeting = dayjs().isAfter(dayjs(event.start));

  useEffect(() => {
    console.log("*******************", event);
    inviteesApi
      .getInvitees({ rtId: event.rtId, empCode: event.empCode })
      .then((res) => {
        console.log("printing invitees response ", res);
        let formatInvitees = [];
        event.empCode === event.createdBy
          ? (formatInvitees = res?.data
              ?.filter((invitee) => invitee.empCode !== event.empCode)
              .map((invitee, ind) => {
                return {
                  value: invitee.empCode,
                  label: `${invitee.name}`,
                };
              }))
          : (formatInvitees = res?.data.map((invitee, ind) => {
              return {
                value: invitee.empCode,
                label: `${invitee.name}`,
              };
            }));
        setInvitees([...formatInvitees]);
        form.setFieldsValue({
          start: dayjs(event.start),
          end: dayjs(event.end),
          roomInvitees: formatInvitees,
          meetingRoomLink: event.meetingRoomLink,
        });
      })
      .catch((err) => {
        setInvitees([]);
        console.log("error in get invitees api ", err);
      });
  }, [event]);

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const resp = await staticApis.getEmployeesData(employeeId);
        const employeesData = resp?.data?.map((employee) => {
          return {
            label: employee.name + " ( " + employee.employeeNo + " ) ",
            value: employee.employeeNo,
          };
        });
        setEmployees([...employeesData]);
      } catch (err) {
        console.log("ERROR IN GET EMPLOYEES DATA API ", err);
      }
    };
    getEmployees();
  }, []);

  const validateStartTime = (_, value) => {
    const endTime = form.getFieldValue("end").format("HH:mm");
    const formattedValue = value.format("HH:mm");

    if (value && endTime && formattedValue >= endTime) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const isTimeUpdated = (event, data) => {
    if (
      event.start.getHours() === data.start.toDate().getHours() &&
      event.start.getMinutes() === data.start.toDate().getMinutes() &&
      event.end.getHours() === data.end.toDate().getHours() &&
      event.end.getMinutes() === data.end.toDate().getMinutes()
    ) {
      return false;
    }
    return true;
  };

  const isInviteesUpdated = (data, comparisonInvitees) => {
    if (data.roomInvitees.length !== comparisonInvitees.length) {
      return true;
    } else {
      if (
        data.roomInvitees.every((invitee) =>
          comparisonInvitees.includes(invitee)
        )
      ) {
        return false;
      }
      return true;
    }
  };

  const isLinkUpdated = (event, data) => {
    return event.meetingRoomLink !== data.meetingRoomLink;
  };

  const isFormUpdated = (data, comparisonInvitees) => {
    if (
      isTimeUpdated(event, data) ||
      isInviteesUpdated(data, comparisonInvitees) || isLinkUpdated(event, data)
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (data) => {
    const { roomInvitees } = data;
    const comparisonInvitees = invitees.map((invitee) => invitee.value);
    if (typeof roomInvitees[0] === "object") {
      data.roomInvitees = roomInvitees.map((invitee) => invitee.value);
      if (!isFormUpdated(data, comparisonInvitees)) {
        toast.error("No Changes Made");
        handlePopupCancel();
        return;
      }
    } else {
      if (!isFormUpdated(data, comparisonInvitees)) {
        toast.error("No Changes Made");
        handlePopupCancel();
        return;
      }
    }

    data.roomInvitees.push(event.empCode);
    const toastId = toast.loading("Loading...");
    try {
      const meetingRoomPayload = {
        startTime: data.start.format("HH:mm"),
        endTime: data.end.format("HH:mm"),
        concurrencyStartDate: event.date.split("T")[0],
        concurrencyEndDate: event.date.split("T")[0],
        mrId: event.mrId,
        rtId: event.rtId,
        meetingRoomLink: data.meetingRoomLink,
      };

      const resp = await meetingRoomApis.getAvailabilityOfMeetingRoom(
        meetingRoomPayload
      );

      console.log("********************", resp);

      if (resp?.data?.noOfUnavailableSlots > 0) {
        toast.error("Meeting room not available");
      } else {
        const roomTimePayload = {
          startTime: data.start.format("HH:mm"),
          endTime: data.end.format("HH:mm"),
          roomTimeId: event.rtId,
          meetingRoomLink: data.meetingRoomLink
        };

        const inviteesPayload = {
          iviteesArray: data.roomInvitees,
        };

        await roomBookingApi.updateRoomTime(roomTimePayload, {
          roomBookingId: event.rbId,
          empCode: event.empCode,
        });
        await inviteesApi.updateInvitees(inviteesPayload, {
          id: event.rtId,
          empCode: event.empCode,
        });
        handlePopupCancel();
        toast.success("Meeting Updated Successfully");
      }
    } catch (err) {
      console.log(
        "error in meeting room availability api in calendar popup",
        err
      );
      handlePopupCancel();
      toast.error("Error in meeting updation");
    }
    toast.dismiss(toastId);
  };

  return (
    <>
      {/*For User */}

      <div id="meeting-heading" style={{ fontSize: "1.5em" }}>
        {dayjs(event.start).format("DD-MM-YYYY")}
      </div>

      {event.createdBy === event.empCode ? (
        event.date < dayjs(new Date()) ? (
          <MeetingInformationPopup meetingData={event} invitees={invitees} />
        ) : (
          <>
            <div
              className="popupDiv"
              style={{ height: "63vh", overflow: "auto" }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                style={{ position: "relative" }}
              >
                <Flex
                  gap={"0.5rem"}
                  style={{ marginTop: "5%" }}
                  vertical
                  justify="space-between"
                >
                  <Flex vertical>
                    <div className="meeting-title-heading">Meeting Room</div>
                    <div className="meeting-title">{event.meetingRoomName}</div>
                  </Flex>
                  <Flex vertical>
                    <div className="meeting-title-heading">Subject</div>
                    <div className="meeting-title">{event.title}</div>
                  </Flex>
                  <Flex vertical>
                    <div className="meeting-title-heading">Agenda</div>
                    <div
                      className="meeting-title"
                      dangerouslySetInnerHTML={{ __html: event.agenda }}
                    />
                  </Flex>
                </Flex>
                {event.createdBy === event.empCode && (
                  <Flex
                    gap={"0.5rem"}
                    style={{
                      position: "absolute",
                      top: -10,
                      right: 0,
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none" }}
                      disabled={isPastMeeting}
                    >
                      Edit
                    </Button>
                    <Button
                      type="link"
                      danger
                      style={{
                        border: "none",
                        fontSize: "18px",
                        position: "relative",
                        top: -4,
                        right: -10,
                      }}
                      onClick={() =>
                        setConfirmationModal({
                          rtId: event.rtId,
                          rbId: event.rbId,
                          empCode: event.empCode,
                        })
                      }
                      disabled={isPastMeeting}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Flex>
                )}
                {event.meetingRoomLink && (
                  <Form.Item name={"meetingRoomLink"} label="Link">
                    <Input
                      size="large"
                      name="meetingRoomLink"
                      id="meetingRoomLink"
                    />
                  </Form.Item>
                )}
                {invitees.length > 0 && (
                  <Form.Item
                    name="roomInvitees"
                    label="Invitees"
                    rules={[
                      { required: true, message: "Room invitees are required" },
                    ]}
                    style={{
                      marginBottom: "0.5rem",
                      marginTop: "0.5rem",
                      width: "70%",
                      fontSize: "0.8rem",
                      color: "grey",
                    }}
                  >
                    <Select
                      placeholder="Select invitees"
                      allowClear
                      onChange={(val) => {
                        form.setFieldValue("roomInvitees", val);
                      }}
                      filterOption={(input, employees) =>
                        (employees?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={employees}
                      mode="multiple"
                    />
                  </Form.Item>
                )}

                <Flex
                  gap="0.5rem"
                  style={{ width: "100%", marginBottom: "0.8rem" }}
                  align="center"
                >
                  <Form.Item
                    name="start"
                    label="Start Time"
                    rules={[
                      {
                        validator: validateStartTime,
                        message: "Start time must be less than end time",
                      },
                    ]}
                  >
                    <TimePicker
                      needConfirm={false}
                      allowClear={false}
                      size="large"
                      style={{ width: "100%" }}
                      defaultValue={dayjs(event.start)}
                      format={"HH:mm"}
                      disabledTime={(time) => {
                        const starttime = dayjs();
                        const currentDate = dayjs().format("YYYY-MM-DD");
                        const currentHour = starttime.hour();
                        const currentMinute = starttime.minute();

                        return {
                          disabledHours: () => {
                            const hours = [];
                            if (event.date === currentDate) {
                              for (let i = 0; i < currentHour; i++)
                                hours.push(i);
                            }

                            return hours;
                          },
                          disabledMinutes: (selectedHour) => {
                            const minutes = [];
                            if (
                              selectedHour === currentHour &&
                              event.date === currentDate
                            ) {
                              for (let i = 0; i < currentMinute; i++)
                                minutes.push(i);
                            }
                            return minutes;
                          },
                        };
                      }}
                      onChange={(val) => {
                        form.setFieldValue("start", val);
                      }}
                      minuteStep={5}
                    />
                  </Form.Item>

                  <Form.Item
                    name="end"
                    label="End Time"
                    initialValue={dayjs(event.end)}
                  >
                    <TimePicker
                      needConfirm={false}
                      allowClear={false}
                      size="large"
                      style={{ width: "100%" }}
                      minuteStep={5}
                      disabledTime={(time) => {
                        const starttime = dayjs();
                        const currentDate = dayjs().format("YYYY-MM-DD");
                        const currentHour = starttime.hour();
                        const currentMinute = starttime.minute();

                        return {
                          disabledHours: () => {
                            const hours = [];
                            if (event.date === currentDate) {
                              for (let i = 0; i < currentHour; i++)
                                hours.push(i);
                            }
                            return hours;
                          },
                          disabledMinutes: (selectedHour) => {
                            const minutes = [];
                            if (
                              selectedHour === currentHour &&
                              event.date === currentDate
                            ) {
                              for (let i = 0; i < currentMinute; i++)
                                minutes.push(i);
                            }
                            return minutes;
                          },
                        };
                      }}
                      defaultValue={dayjs(event.end)}
                      format={"HH:mm"}
                      onChange={(val) => {
                        form.setFieldValue("end", val);
                      }}
                    />
                  </Form.Item>
                </Flex>
              </Form>
            </div>
          </>
        )
      ) : (
        <MeetingInformationPopup meetingData={event} invitees={invitees} />
      )}

      <Modal
        open={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        footer={null}
      >
        <DeletePopup
          modalData={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          handlePopupCancel={handlePopupCancel}
        />
      </Modal>
    </>
  );
};

export default CalendarPopup;
